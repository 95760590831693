import React from "react";
import './home.css'
import { useNavigate } from "react-router-dom";

import BannerImg from "../../../Assets/img/Home/home-vector.png";
import Cyprus from "../../../Assets/img/Cyprus/cyprus-why-choose.png";
import Dubai from "../../../Assets/img/Dubai/dubai-why-choose.png";
import Malta from "../../../Assets/img/malta/malta-why-choose.png";
import Greece from "../../../Assets/img/greece/greece-why-choose.png";

// swiper 
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';


// Example for the Hero Section
const HeroSection = () => (
    <div className='hero-section'
         style={{
             backgroundImage: `url(${BannerImg})`,
             backgroundRepeat: 'no-repeat',
             backgroundSize: 'contain',
             backgroundPosition: 'center center'
         }}
    >
        <div className='hero-data'>
            <h1>Global Tax-Free Residency & Business Opportunities</h1>

            <p>Explore tax-friendly programs around the world to optimize your personal and business strategies.</p>
        </div>
    </div>
);

// Example for the Global Tax Benefits Section
const GlobalBenefits = () => {
    const benefits = [
        {id: 1, title: "Tax-Free Foreign Income", icon: "🧳" },
        { id: 2, title: "No Capital Gains Tax", icon: "💼" },
        { id: 3, title: "Wealth Preservation", icon: "💰" },
        { id: 4, title: "Business-Friendly Environment", icon: "🏢" },
        { id: 5, title: "Strategic Market Access", icon: "🌍" },
    ];

    return (
        <section className="global-benefits">
            <h2 className='section-title'>Global Tax Benefits</h2>
            <br/>
            <div className="benefits-grid container">
                {benefits.map((benefit) => (
                    <div key={benefit.id} className="benefit-item">
                        <span className="benefit-icon">{benefit.icon}</span>
                        <h3>{benefit.title}</h3>
                    </div>
                ))}
            </div>
        </section>
    );
};

// Example for the How It Works Section
const HowItWorks = () => (
    <section className="how-it-works ">
        <h1 className='section-title'>How It Works</h1>
        <br/>
        <div className="steps">
            <div className="step">
                <h1 className='steps-number'>01</h1>
                <p>Choose Your Preferred Country</p>
            </div>
            <div className="step">
                <h1 className='steps-number'>02</h1>
                <p>Review Eligibility and Program Details</p>
            </div>
            <div className="step">
                <h1 className='steps-number'>03</h1>
                <p>Contact Us for Expert Guidance</p>
            </div>
        </div>
    </section>
);

// Example for the Featured Destinations Section
const FeaturedDestinations = () => {
    const destinations = [
        { id: 1, name: "Malta", description: "Tax-Free Residency & Business Opportunities", img: Malta, path: "/malta" },
        { id: 2, name: "Cyprus", description: "Business-Friendly Environment", img: Cyprus, path: "/cyprus" },
        { id: 3, name: "Dubai", description: "Prime Destination for Entrepreneurs", img: Dubai, path: "/dubai" },
        { id: 4, name: "Greece", description: "Golden Visa & Real Estate Investment", img: Greece, path: "/greece" }

    ];
    
    const navigate = useNavigate()
    const handleNavigate = (path) => {
        navigate(path);  // Navigate to the destination's path
    };
    
    return (
        <section className="featured-destinations">
            <h2 className="section-title">Featured Destinations</h2>
            <br/>
            <div className='container'>
                <Swiper
                    spaceBetween={30}
                    slidesPerView={1} // Default to 1 slide per view
                    navigation
                    modules={[Navigation]}
                    breakpoints={{
                        // when window width is >= 640px
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                        // when window width is >= 768px
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 30,
                        },
                        // when window width is >= 1024px
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 40,
                        },
                    }}
                >
                    {destinations.map((destination) => (
                        <SwiperSlide key={destination.id}>
                            <div className="destination-card">
                                <img src={destination.img} alt={destination.name}/>
                                <h3>{destination.name}</h3>
                                <p>{destination.description}</p>
                                <button 
                                    className="cta-button"
                                    onClick={() => handleNavigate(destination.path)}
                                >
                                    Explore {destination.name}
                                </button>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    );
};


// Example for the Call to Action Section
const CallToAction = () => (
    <section className="call-to-action">
        <h2>Ready to Optimize Your Tax Strategy?</h2>
        <button className="cta-button">Contact Us for Personalized Guidance</button>
    </section>
);

// Main Homepage Component
const HomePage = () => {
    return (
        <div className="homepage">
            <HeroSection/>
            <GlobalBenefits/>
            <HowItWorks/>
            <FeaturedDestinations/>
        </div>
    );
};

export default HomePage;
