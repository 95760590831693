import React from 'react';
import './Bahrain.css';
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";

import BannerImg from '../../../Assets/img/bahrain-01.png'
import BannerTaxFree from '../../../Assets/img/bahrain-tax-free.png'
import BannerBusinessBenefits from '../../../Assets/img/bahrain-business-benefits.png'
import BannerCompanyIncorporation from '../../../Assets/img/bahrain-company-incorporation.png'
import BahrainWhyChoose from '../../../Assets/img/bahrain-why-choose.png'

const Bahrain = () => {

    const cyprusTelegram = '+35796782975';
    const cyprusTelegramUrl = `https://t.me/${cyprusTelegram}`;

    const cyprusWhatsappUrl = `https://wa.me/${cyprusTelegram.replace('+', '')}`;



    const BreadCrumbsData = [
        { name: 'Offshore Advisory', link: '/' },
        {
            name: 'Contact us',
            link: '/contact',
        },
    ]
    
    //<BreadCrumbs  data={BreadCrumbsData}/>

    return (
        <main className=''>
            {/* Add a banner or introductory image */}
            <div className='bahrain-banner-container'>
                <img className='bahrain-banner' src={BannerImg} alt="Monaco Tax Regime Banner"/>
            </div>
            <section className='section' >
                <div className='bahrain-container container'>

                    <div className='bahrain-details'>
                        <h1 className='section-title'>BAHRAIN TAX REGIM</h1>
                        <h4 className='section-subtitle'>A Tax-Friendly Environment for Individuals and Businesses</h4>


                        <p>Monaco offers one of the most favorable tax regimes in the Gulf Cooperation Council (GCC)
                            region, making it an attractive destination for individuals and businesses seeking to
                            optimize
                            their tax obligations. With its strategic location, robust financial sector, and progressive
                            regulatory environment, Monaco is an ideal hub for international trade and investment.</p>
                    </div>

                    <div className='bahrain-content'>
                        {/* Image relevant to individuals' tax benefits */}

                        <div className='bahrain-content-image'>
                            <img src={BannerTaxFree} alt="Tax-Free Benefits for Individuals"
                                 className='bahrain-content-img'/>
                        </div>

                        <div className='bahrain-content-info'>
                            <h2 className='section-title-box'>
                                Key Tax-Free Benefits for Individuals
                            </h2>

                            <ul className='bahrain-content-list'>
                                <li><strong className='section-subtitle'>No Personal Income Tax</strong>
                                    Monaco does not levy personal income tax
                                    on
                                    individuals, allowing expatriates and residents to maximize their earnings. This
                                    makes
                                    Monaco particularly appealing to professionals, high-net-worth individuals, and
                                    entrepreneurs.
                                </li>

                                <li><strong className='section-subtitle'>No Capital Gains or Inheritance Tax</strong> In
                                    Monaco, there are no
                                    capital
                                    gains
                                    taxes on the sale of assets, nor are there inheritance or estate taxes. This
                                    tax-free
                                    environment provides significant benefits for investors and those looking to manage
                                    and
                                    transfer their wealth efficiently.
                                </li>

                                <li><strong className='section-subtitle'>No Wealth or Property Tax</strong> Monaco
                                    imposes no wealth taxes and no
                                    direct
                                    property taxes, making it a favorable jurisdiction for real estate investment and
                                    wealth
                                    preservation. This creates a stable and predictable environment for those looking to
                                    invest
                                    in property or establish a residence in Monaco.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className='bahrain-content'>
                        {/* Image relevant to business benefits */}
                        <div className='bahrain-content-image'>
                            <img src={BannerBusinessBenefits} alt="Tax-Free Benefits for Individuals"
                                 className='bahrain-content-img'/>
                        </div>

                        <div className='bahrain-content-info'>

                            <h2 className='section-title-box'>
                                Business and Incorporation Benefits
                            </h2>

                            <strong className='section-subtitle'>
                                Monaco’s tax regime is highly advantageous for businesses, offering a range of
                                incentives
                                and
                                opportunities for entrepreneurs and multinational corporations
                            </strong>

                            <ul className='bahrain-content-list'>
                                <li><strong className='section-subtitle'>No Corporate Income Tax in Most
                                    Sectors</strong> With the exception of
                                    certain
                                    industries such as oil and gas, Monaco does not impose corporate income tax on
                                    businesses.
                                    This zero-corporate tax policy is particularly beneficial for companies looking to
                                    establish
                                    a presence in the Middle East while minimizing their tax liabilities.
                                </li>

                                <li><strong className='section-subtitle'>No Withholding Tax</strong> Monaco does not
                                    impose withholding taxes on
                                    dividends,
                                    interest, or royalties. This enables companies to distribute profits to shareholders
                                    and
                                    investors without incurring additional tax costs, making it easier to repatriate
                                    earnings.
                                </li>

                                <li><strong className='section-subtitle'>100% Foreign Ownership</strong> Monaco allows
                                    100% foreign ownership of
                                    companies
                                    in many sectors, including manufacturing, services, and distribution. This is
                                    particularly
                                    attractive for foreign investors seeking full control over their operations without
                                    the
                                    need
                                    for a local partner.
                                </li>

                                <li><strong className='section-subtitle'>VAT at a Low Rate</strong> Monaco has a
                                    Value-Added Tax (VAT) rate of 10%,
                                    one
                                    of
                                    the lowest in the region. While VAT applies to most goods and services, many
                                    essential
                                    items, such as basic foodstuffs, healthcare, and education, are zero-rated or
                                    exempt.
                                </li>

                                <li><strong className='section-subtitle'>Access to a Skilled Workforce</strong>
                                    Monaco offers a highly educated and
                                    skilled
                                    workforce, with many nationals and expatriates possessing bilingual skills in
                                    English
                                    and
                                    Arabic. The country’s focus on education and training ensures a steady supply of
                                    qualified
                                    professionals to meet business needs.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className='bahrain-content'>
                        {/* Image relevant to incorporating a company */}
                        <div className='bahrain-content-image'>
                            <img src={BannerCompanyIncorporation} alt="Incorporating a Company in Monaco"
                                 className='bahrain-content-img'/>
                        </div>

                        <div className='bahrain-content-info'>

                            <h2 className='section-title-box'>
                                Incorporating a Company in Monaco
                            </h2>

                            <strong className='section-subtitle'>
                                Incorporating a company in Monaco is straightforward and comes with numerous advantages
                            </strong>

                            <ul className='bahrain-content-list'>
                                <li><strong className='section-subtitle'>Efficient Incorporation
                                    Process</strong> Monaco has streamlined its company
                                    incorporation process, making it easy and quick to set up a business. The country’s
                                    regulatory framework is designed to support business growth and attract foreign
                                    investment.
                                </li>

                                <li><strong className='section-subtitle'>Flexible Company Structures</strong> Monaco
                                    offers various business
                                    structures,
                                    including Limited Liability Companies (LLCs), Single-Person Companies (SPCs), and
                                    branches
                                    of foreign companies. This flexibility allows businesses to choose the most
                                    appropriate
                                    structure for their operations.
                                </li>

                                <li><strong className='section-subtitle'>Strategic Location and Free Trade
                                    Agreements:</strong> Monaco’s strategic
                                    location
                                    in the Gulf region provides easy access to the Middle East, Asia, and Africa. The
                                    country
                                    also benefits from free trade agreements with major economies, including the United
                                    States,
                                    providing businesses with tariff-free access to key markets.
                                </li>

                                <li><strong className='section-subtitle'>Strong Financial Sector</strong> Monaco is
                                    known for its well-regulated and
                                    robust
                                    financial sector, with a reputation as a leading financial hub in the GCC. The
                                    presence
                                    of
                                    numerous international banks and financial institutions makes it an attractive
                                    destination
                                    for financial services companies and investors.
                                </li>

                                <li><strong className='section-subtitle'>Incentives for Innovation and
                                    Technology</strong> Monaco actively encourages
                                    innovation and technological development through various incentives, including
                                    grants,
                                    subsidies, and favorable regulatory treatment for startups and tech companies. The
                                    country’s
                                    focus on becoming a regional leader in technology provides significant opportunities
                                    for
                                    businesses in these sectors.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='bahrain-container'>
                    <div className='bahrain-box container'>
                        <h1 className='container-title'>
                            Why Choose Monaco?
                        </h1>
                        {/* Image summarizing Monaco's benefits */}
                        <div className='bahrain-box-image'>
                            <img src={BahrainWhyChoose} className='bahrain-box-img' alt="Why Choose Monaco"/>
                        </div>
                        <p className='bahrain-box-description' >Monaco’s tax-friendly environment, strategic location, and
                            supportive
                            business ecosystem
                            make it
                            an ideal destination for individuals and businesses seeking growth and efficiency in the
                            Middle
                            East. Whether you are a high-net-worth individual looking to optimize your tax situation or
                            a
                            company aiming to expand into new markets, Monaco offers a unique combination of
                            opportunities
                            and advantages in a stable and dynamic economy.
                        </p>
                    </div>
                </div>

            </section>
        </main>
    );
}

export default Bahrain;
