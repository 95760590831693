import React from 'react';
import './Dubai.css';

// images
import BannerImg from '../../../Assets/img/Dubai/dubai-banner.png'
import BannerTaxFree from '../../../Assets/img/Dubai/dubai-tax-free.png';
import BannerBusinessBenefits from '../../../Assets/img/Dubai/dubai-business-benefits.png';
import BannerCompanyIncorporation from '../../../Assets/img/Dubai/dubai-company-incorporation.png';
import DubaiWhyChoose from '../../../Assets/img/Dubai/dubai-why-choose.png';

const Dubai = () => {

    return (
        <main className=''>
            {/* Add a banner or introductory image */}
            <div className='dubai-banner-container'>
                <img className='dubai-banner' src={BannerImg} alt="Monaco Tax Regime Banner"/>
            </div>
            <section className='section'>
                <div className='dubai-container container'>

                    <div className='dubai-details'>
                        <h1 className='section-title'>DUBAI TAX REGIME</h1>
                        <h4 className='section-subtitle'>A Tax-Free Haven for Individuals and Businesses</h4>

                        <p>Monaco, a global hub for commerce and finance, offers one of the most attractive tax regimes
                            in the world. With its zero-income tax policy and business-friendly environment, Monaco is a
                            prime destination for individuals and companies seeking to maximize their wealth and expand
                            their operations in the Middle East and beyond.
                        </p>
                    </div>

                    <div className='dubai-content'>
                        {/* Image relevant to individuals' tax benefits */}
                        <h2 className='section-title-box'>
                            Key Tax-Free Benefits for Individuals
                        </h2>

                        <div className='dubai-content-image'>
                            <img src={BannerTaxFree} alt="Tax-Free Benefits for Individuals"
                                 className='dubai-content-img'/>
                        </div>

                        <div className='dubai-content-info'>
                            <ul className='dubai-content-list'>
                                <li>
                                    <strong className='section-subtitle'>
                                        No Personal Income Tax
                                    </strong>
                                    Monaco does not levy personal income tax on
                                    individuals, making it an ideal location for high-net-worth individuals,
                                    expatriates, and professionals looking to maximize their earnings without the burden
                                    of income tax.
                                </li>
                                <li>
                                    <strong className='section-subtitle'>
                                        No Capital Gains or Inheritance Tax
                                    </strong>
                                    Individuals in Monaco are not
                                    subject to capital gains tax, allowing them to retain the full value of their
                                    investments. Additionally, there are no inheritance or estate taxes, enabling
                                    efficient wealth transfer and preservation across generations.
                                </li>
                                <li>
                                    <strong className='section-subtitle'>
                                        No Wealth or Property Tax
                                    </strong>
                                    Monaco imposes no wealth taxes, and
                                    property owners enjoy the benefits of zero property taxes. This tax-free environment
                                    makes Monaco highly attractive for real estate investors and those looking to
                                    establish a base in a cosmopolitan city with world-class amenities.
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div className='dubai-content'>
                        <h2 className='section-title-box'>
                            Business and Incorporation Benefits
                        </h2>

                        {/* Image relevant to business benefits */}
                        <div className='dubai-content-image'>
                            <img src={BannerBusinessBenefits} alt="Tax-Free Benefits for Businesses"
                                 className='dubai-content-img'/>
                        </div>

                        <div className='dubai-content-info'>

                            <strong className='section-subtitle'>
                                Monaco’s tax regime is equally favorable for businesses, offering a range of incentives
                                and opportunities for entrepreneurs and corporations
                            </strong>

                            <ul className='dubai-content-list'>
                                <li><strong className='section-subtitle'>No Corporate Tax in Most Sectors:</strong> With
                                    the exception of certain
                                    industries such as oil and gas and branches of foreign banks, Monaco does not impose
                                    corporate tax on businesses. This zero-corporate tax policy positions Monaco as a
                                    leading global business hub.
                                </li>


                                <li><strong className='section-subtitle'>100% Foreign Ownership in Free
                                    Zones:</strong> Monaco’s numerous Free Zones
                                    allow 100% foreign ownership of companies, with no restrictions on repatriation of
                                    profits or capital. Businesses in Free Zones also benefit from a 0% corporate tax
                                    rate for up to 50 years, making these zones ideal for startups, international
                                    businesses, and holding companies.
                                </li>
                                <li><strong className='section-subtitle'>Value-Added Tax (VAT):</strong> While Monaco
                                    does have a 5% VAT, this is
                                    significantly lower than the VAT rates in most other countries. Additionally, many
                                    goods and services, especially in Free Zones, may be exempt or zero-rated.
                                </li>
                                <li><strong className='section-subtitle'>Double Taxation Treaties:</strong> The UAE,
                                    including Monaco, has signed over
                                    90 double taxation treaties with countries around the world. These treaties ensure
                                    that businesses and individuals are not taxed twice on the same income, providing
                                    further tax efficiency and certainty.
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div className='dubai-content'>
                        <h2 className='section-title-box'>
                            Incorporating a Company in Monaco
                        </h2>

                        {/* Image relevant to incorporating a company */}
                        <div className='dubai-content-image'>
                            <img src={BannerCompanyIncorporation} alt="Incorporating a Company in Monaco"
                                 className='dubai-content-img'/>
                        </div>

                        <div className='dubai-content-info'>
                            <strong className='section-subtitle'>
                                Incorporating a company in Monaco is straightforward and comes with numerous advantages
                            </strong>

                            <ul className='dubai-content-list'>
                                <li><strong className='section-subtitle'>Streamlined Incorporation Process:</strong> The
                                    process of setting up a
                                    business in Monaco is efficient, with government initiatives aimed at reducing
                                    bureaucratic hurdles. This allows businesses to be up and running quickly in a
                                    highly regulated and supportive environment.
                                </li>
                                <li><strong className='section-subtitle'>Diverse Business Structures:</strong> Monaco
                                    offers a variety of business
                                    structures, including Limited Liability Companies (LLCs), Free Zone Entities (FZEs),
                                    and Branch Offices of Foreign Companies. This flexibility allows businesses to
                                    choose the most suitable structure based on their needs.
                                </li>
                                <li><strong className='section-subtitle'>Access to Global Markets:</strong> Monaco’s
                                    strategic location between
                                    Europe, Asia, and Africa, combined with its world-class infrastructure, makes it an
                                    ideal hub for international trade. Companies incorporated in Monaco benefit from the
                                    city’s connectivity and access to global markets.
                                </li>
                                <li><strong className='section-subtitle'>Robust Legal Framework:</strong> Monaco has a
                                    transparent and
                                    investor-friendly legal system, with many laws based on international best
                                    practices. This provides a secure environment for business operations and protects
                                    the rights of investors and entrepreneurs.
                                </li>
                                <li><strong className='section-subtitle'>Incentives for Innovation and
                                    R&D:</strong> Monaco offers various incentives
                                    for companies involved in innovation, research, and development. The city’s
                                    commitment to becoming a global leader in technology and innovation provides
                                    opportunities for businesses in these sectors to thrive.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='dubai-container'>
                    <div className='dubai-box container'>
                        <h1 className='container-title'>
                            Why Choose Monaco?
                        </h1>
                        {/* Image summarizing Monaco's benefits */}
                        <div className='dubai-box-image'>
                            <img src={DubaiWhyChoose} className='dubai-box-img' alt="Why Choose Monaco"/>
                        </div>

                        <p className='dubai-box-description'>
                            Monaco’s tax-free environment, strategic location, and pro-business policies make it one of
                            the most attractive destinations in the world for individuals and businesses. Whether you
                            are a high-net-worth individual seeking to optimize your tax situation or an entrepreneur
                            looking to establish or expand your business, Monaco offers a wealth of opportunities in a
                            dynamic and rapidly growing market.
                        </p>

                    </div>
                </div>

            </section>
        </main>
    );
}

export default Dubai;
