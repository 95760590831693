import React from 'react';
import './Monaco.css';

// images
import BannerImg from '../../../Assets/img/italy/italy-banner.png'
import BannerTaxFree from '../../../Assets/img/Monaco/monaco-tax-free.png';
import BannerBusinessBenefits from '../../../Assets/img/Monaco/monaco-business-benefits.png';
import BannerCompanyIncorporation from '../../../Assets/img/Monaco/monaco-company-incorporation.png';
import DubaiWhyChoose from '../../../Assets/img/Monaco/monaco-why-choose.png';

const Italy = () => {

    return (
        <main className=''>
            {/* Add a banner or introductory image */}
            <div className='monaco-banner-container'>
                <img className='monaco-banner' src={BannerImg} alt="Monaco Tax Regime Banner"/>
            </div>
            <section className='section' >
                <div className='monaco-container container'>

                    <div className='monaco-details'>
                        <h1 className='section-title'>
                            The Italian Non-Domiciled Tax Regime
                        </h1>
                        <h4 className='section-subtitle'>
                            Introduced by the 2017 Budget Law (Law no. 232/2016), the optional “non-dom” (i.e.,
                            non-domiciled) tax regime aims to attract foreign taxpayers to Italy.
                        </h4>
                        <p>
                            Notably, going against the general principle of income being taxed on a worldwide basis, the
                            regime allows individuals who are tax residents in Italy the option of not including
                            personal income generated overseas in their personal income tax (“IRPEF”).
                        </p>
                        <br/>
                        <p>
                            For each tax period in which this option is valid, such income will be subject to a
                            substitute tax, set at a flat rate of €100,000. This tax, which is not deductible from any
                            other tax or contribution, will be paid in a single payment by the date set for the payment
                            of the balance of income taxes (30 June, or 30 July with an additional 0.4%).
                        </p>
                        <br/>
                        <p>
                            One can opt into the non-dom regime for (i) the annual income tax return relating to the
                            first fiscal year in which one becomes an Italian tax resident; or (ii) the tax return of
                            the subsequent fiscal year.
                        </p>
                        <br/>
                        <p>
                            One can opt into the non-dom regime for (i) the annual income tax return relating to the
                            first fiscal year in which one becomes an Italian tax resident; or (ii) the tax return of
                            the subsequent fiscal year.
                        </p>
                    </div>

                    <div className='monaco-details'>
                        <strong className='section-subtitle'>
                            Subjective scope of application
                        </strong>
                        <p>
                            The rule covers various types of individuals who transfer their tax residence to
                            Italy. These must not have been fiscally resident in Italy in at least nine of the
                            ten tax periods (monitoring period) prior to the start of them taking up the option.
                            <br/>
                            Once signed up to the Italian non-dom regime, the main beneficiary may request it
                            also cover one or more family member as defined in the Italian Civil Code provided
                            that they are also resident in Italy during the validity period of the option. In
                            this case, an additional substitute tax of €25,000 is available per family member to
                            whom the option is extended.
                        </p>
                        <br/>
                        <strong className='section-subtitle'>
                            Objective scope of application
                        </strong>
                        <p>
                            The substitute tax, both with reference to the main beneficiary and to their family
                            members, affects income generated abroad. The optional regime does not apply to
                            capital gains from the disposal of qualified shares within the first five fiscal
                            years of validity of the option. Finally, the beneficiary may choose, for themselves
                            or their family members, not to claim the substitute tax with reference to income
                            generated in one or more foreign states or territories, giving specific indication
                            of which when exercising the option or with an amendment at a later date. In such
                            cases, the ordinary IRPEF regime applies for income generated in the designated
                            states or territories, with the taxpayer receiving a tax credit for said income.
                        </p>
                        <br/>

                        <strong className='section-subtitle'>
                            Duration of the option
                        </strong>
                        <p>
                            The substitute tax regime on income generated abroad ceases 15 years after the first
                            tax period under which the option was taken up ends. However, the taxpayer or family
                            members to whom it was extended can revoke it in any tax return after the first in
                            which the option was taken up. The benefits of the option also cease in the event of
                            failure to pay, in whole or in part, the substitute tax, without prejudice to the
                            effects produced in previous tax periods. This is also the case if tax residency is
                            transferred from Italy to another state or territory, with benefits ending with the
                            tax period in which the beneficiary lost residence in Italy for tax purposes.
                        </p>
                        <br/>
                        <strong className='section-subtitle'>
                            Other considerations
                        </strong>
                        <p>
                            Taxpayers who exercise the option (and family members to whom it is extended) for
                            the substitute tax on foreign income and with reference to the tax periods of
                            validity of the option, also benefit from an exemption on tax on the value of (i)
                            properties they own abroad; and (ii) financial products, current accounts and
                            savings books referred to Legislative Decree 201/2011.
                            <br/>
                            Finally, opting into the Italian non-dom regime cannot be combined with other tax
                            regimes benefiting individuals, such as the 7% flat rate for pensioners or the
                            inbound workers regime. For inheritances and donations made in tax periods during
                            which the option is being exercised, tax is due only on assets and rights forming
                            part of such in Italy.
                        </p>
                    </div>

                </div>
            </section>
        </main>
    );
}

export default Italy;
